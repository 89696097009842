import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import { addForm } from "./../../../config/forms_api_calls";
import { getIntegration } from "./../../../config/reseller_api_calls";

import $ from "jquery";
import FormBuilderClass from "../../../components/Reseller/FormBuilderClass";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import HelperClass from "./../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import dateFormat, { masks } from "dateformat";
import Tooltip from "@material-ui/core/Tooltip";
import RichTextEditorComponent from "../../../components/Reseller/RichTextEditorComponent";

const auth = new HelperClass();

class AddForm extends Component {
  constructor() {
    super();
    this.state = {
      errorMessage: "",
      successMessage: "",
      tableLoader: true,
      disabled: false,
      form_title: "",
      form_desc: "",
      form_content: [],
      showOnDashboard: false,
      form_sequence: "1",
      form_sequence_error: "",
      btn_text: "",
      submission_text: "",
      allow_multiple: false,
      is_default: false,
      attach_to: false,
      allow_submit: true,
      link_form: false,
      external_link: "",
      groups: [],
      copiedBadge: "",
      showTooltip: false,
      va_intergation: "No",
    };
  }

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  async componentDidMount() {
    this.setState({
      errorMessage: "",
      successMessage: "",
      tableLoader: true,
    });
    this.get_integration_data();
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  };

  handleGroupsChange = (groups) => {
    this.setState({ groups: groups });
  };

  handleFormContentChange = (formContent) => {
    this.setState({ form_content: formContent });
  };

  handleEditorChange = (updatedContent) => {
    //console.log('updatedContent: ',updatedContent);
    this.setState({ form_desc: updatedContent });
  };

  handleFormSubmit = () => {
    const {
      form_title,
      form_desc,
      form_content,
      form_sequence,
      btn_text,
      submission_text,
      form_sequence_error,
      showOnDashboard,
      allow_multiple,
      is_default,
      attach_to,
      allow_submit,
      link_form,
      external_link,
      groups,
    } = this.state;
    let isValidated = true;

    const isValidContent = form_content.every((form) => {
      const validQuestions = form.questions.filter(
        (question) =>
          question.questionText.trim() !== "" &&
          question.answerType.trim() !== ""
      );
      return validQuestions.length > 0;
    });

    if (!form_title || !form_desc) {
      isValidated = false;
      this.setState({
        errorMessage: "Form title and description are required.",
      });
    } else if (!link_form && (!isValidContent || form_content.length <= 0)) {
      isValidated = false;
      this.setState({
        errorMessage:
          "Form must have at least one valid question with both question text and answer type filled.",
      });
    } else if (showOnDashboard) {
      if (form_sequence_error !== "") {
        isValidated = false;
        this.setState({
          errorMessage: "Please enter correct sequence number.",
        });
      } else if (!btn_text || btn_text === "") {
        isValidated = false;
        this.setState({
          errorMessage: "Button text must be provided.",
        });
      }
    } else if (link_form) {
      if (!external_link || external_link === "") {
        isValidated = false;
        this.setState({
          errorMessage: "Form link cannot be empty.",
        });
      }
    }

    const filteredContent = form_content.map((form) => ({
      ...form,
      questions: form.questions
        .filter(
          (question) =>
            question.questionText.trim() !== "" &&
            question.answerType.trim() !== ""
        )
        .map((question) => ({
          ...question,
          options:
            (question.answerType === "checkbox" ||
              question.answerType === "radio") &&
              question.options
              ? question.options.filter((option) => option.trim() !== "")
              : question.options,
        })),
    }));

    setTimeout(() => {
      this.setState({ errorMessage: "" });
    }, 2500);

    const content = JSON.stringify(filteredContent);
    if (isValidated) {
      const groups_string = JSON.stringify(groups);
      this.saveForm(
        form_title,
        form_desc,
        content,
        form_sequence,
        btn_text,
        submission_text,
        showOnDashboard,
        allow_multiple,
        is_default,
        groups_string,
        attach_to,
        allow_submit,
        link_form,
        external_link
      );
    }
  };

  get_integration_data = async () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getIntegration(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("get_integration_data: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let va_intergation = "No";
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          console.log('servicesResponce.data.data[i]["integration_type"]' + servicesResponce.data.data[i]["active"] + " " + servicesResponce.data.data[i]["integration_type"] + " " + servicesResponce.data.data[i]["status"]);
          if (
            servicesResponce.data.data[i]["integration_type"] ===
            "voicealerts" &&
            servicesResponce.data.data[i]["active"] === 1 &&
            servicesResponce.data.data[i]["status"] === "Yes"
          ) {
            va_intergation = "Yes";
          }
        }
      }
      console.log("va_intergation: ", va_intergation);
      this.setState({
        va_intergation: va_intergation,
      });
    } else {
    }
  };

  saveForm = async (
    title,
    desc,
    content,
    form_sequence,
    btn_text,
    submission_text,
    showOnDashboard,
    allow_multiple,
    is_default,
    groups_string,
    attach_to,
    allow_submit,
    link_form,
    external_link
  ) => {
    let show_on_dashboard = showOnDashboard ? "1" : "0";
    let allowmultiple = allow_multiple ? "1" : "0";
    let isdefault = is_default ? "1" : "0";
    let attachto = attach_to ? "1" : "0";
    let allowsubmit = allow_submit ? "1" : "0";
    let linkform = link_form ? "1" : "0";
    const saveForm = await addForm(
      auth.getAccount(),
      auth.getToken(),
      title,
      desc,
      content,
      form_sequence,
      btn_text,
      submission_text,
      show_on_dashboard,
      allowmultiple,
      isdefault,
      groups_string,
      attachto,
      allowsubmit,
      linkform,
      external_link
    );
    console.log("saveForm: ", saveForm.data);
    if (
      saveForm.data.status === 403 ||
      saveForm.data.errors === "authentication missing" ||
      saveForm.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveForm.data.status === 404 &&
      saveForm.data.message === "error"
    ) {
      this.setState({
        errorMessage: "There was some error while adding the form.",
      });
    } else if (
      saveForm.data.status === 200 &&
      saveForm.data.message === "success"
    ) {
      this.setState({
        successMessage: "Form added successfully.",
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      this.setState({
        errorMessage: "There was some error while adding the form.",
      });
    }
  };

  handleOptionChange = (event) => {
    this.setState({
      showOnDashboard: event.target.checked,
    });
  };

  handleDefaultChange = (event) => {
    this.setState({
      is_default: event.target.checked,
    });
  };

  handleProfileChange = (event) => {
    this.setState({
      attach_to: event.target.checked,
    });
  };

  handleSubmitChange = (event) => {
    this.setState({
      allow_submit: event.target.checked,
    });
  };

  handleMultipleChange = (event) => {
    this.setState({
      allow_multiple: event.target.checked,
    });
  };

  handleLinkChange = (event) => {
    this.setState({
      link_form: event.target.checked,
    });
  };

  handleSequenceChange = async (event) => {
    const { target } = event;
    let value = target.value;
    let parsedValue = parseInt(target.value);
    if (!parsedValue || parsedValue < 1 || parsedValue > 9) {
      this.setState({
        form_sequence_error: "The sequence can only be between 1-9",
        form_sequence: value,
      });
    } else {
      this.setState({
        form_sequence_error: "",
        form_sequence: value,
      });
    }
  };

  handleBadgeClick = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        this.setState({ copiedBadge: content, showTooltip: true });
        setTimeout(() => {
          this.setState({ copiedBadge: "", showTooltip: false });
        }, 700);
      })
      .catch((error) => {
        console.error("Unable to copy text to clipboard:", error);
      });
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/forms/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Forms
                  </Link>
                  <Typography color="textPrimary" className="">
                    Add New Form
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                <div className="nk-block nk-block-lg">
                  <div
                    className="card card-bordered"
                    style={{ minHeight: "270px" }}
                  >
                    <div className="card-inner">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <h5
                        className="card-title"
                        style={{ marginBottom: "30px" }}
                      >
                        Add New Form
                      </h5>
                      <div className="row g-4">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <MutextField
                              required
                              id="form_title"
                              name="form_title"
                              type="text"
                              label="Form Title"
                              value={this.state.form_title}
                              onChange={this.handleChange}
                              inputProps={{ maxLength: 100 }}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <RichTextEditorComponent
                              agreementContent={this.state.form_desc}
                              onContentChange={this.handleEditorChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <ul class="nk-kycfm-control-list g-3">
                              <li className="nk-kycfm-control-item">
                                <input
                                  className="nk-kycfm-control"
                                  type="checkbox" // Use checkbox instead of radio for a toggle switch
                                  name="showOnDashboard"
                                  id="showOnDashboard"
                                  data-title="Show on dashboard"
                                  checked={this.state.showOnDashboard}
                                  onChange={this.handleOptionChange}
                                />
                                <label
                                  className="nk-kycfm-label"
                                  htmlFor="showOnDashboard"
                                >
                                  <span className="nk-kycfm-label-icon">
                                    <div className="label-icon fs-22px">
                                      <em class="icon ni ni-dashboard"></em>
                                    </div>
                                  </span>
                                  <span className="nk-kycfm-label-text">
                                    Show on dashboard
                                  </span>
                                </label>
                              </li>
                              <li className="nk-kycfm-control-item">
                                <input
                                  className="nk-kycfm-control"
                                  type="checkbox" // Use checkbox instead of radio for a toggle switch
                                  name="allow_multiple"
                                  id="allow_multiple"
                                  data-title="Allow Mutiple Submission"
                                  checked={this.state.allow_multiple}
                                  onChange={this.handleMultipleChange}
                                />
                                <label
                                  className="nk-kycfm-label"
                                  htmlFor="allow_multiple"
                                >
                                  <span className="nk-kycfm-label-icon">
                                    <div className="label-icon fs-22px">
                                      <em class="icon ni ni-repeat-v"></em>
                                    </div>
                                  </span>
                                  <span className="nk-kycfm-label-text">
                                    Allow Mutiple Submission
                                  </span>
                                </label>
                              </li>
                              <li className="nk-kycfm-control-item">
                                <input
                                  className="nk-kycfm-control"
                                  type="checkbox" // Use checkbox instead of radio for a toggle switch
                                  name="is_default"
                                  id="is_default"
                                  data-title="Default"
                                  checked={this.state.is_default}
                                  onChange={this.handleDefaultChange}
                                />
                                <label
                                  className="nk-kycfm-label"
                                  htmlFor="is_default"
                                >
                                  <span className="nk-kycfm-label-icon">
                                    <div className="label-icon fs-22px">
                                      <em class="icon ni ni-flag"></em>
                                    </div>
                                  </span>
                                  <span className="nk-kycfm-label-text">
                                    Make Default
                                  </span>
                                </label>
                              </li>
                              <li className="nk-kycfm-control-item">
                                <input
                                  className="nk-kycfm-control"
                                  type="checkbox" // Use checkbox instead of radio for a toggle switch
                                  name="attach_to"
                                  id="attach_to"
                                  data-title="Attach to Profile"
                                  checked={this.state.attach_to}
                                  onChange={this.handleProfileChange}
                                />
                                <label
                                  className="nk-kycfm-label"
                                  htmlFor="attach_to"
                                >
                                  <span className="nk-kycfm-label-icon">
                                    <div className="label-icon fs-22px">
                                      <em class="icon ni ni-account-setting-fill"></em>
                                    </div>
                                  </span>
                                  <span className="nk-kycfm-label-text">
                                    Attach to Profile
                                  </span>
                                </label>
                              </li>
                              <li className="nk-kycfm-control-item">
                                <input
                                  className="nk-kycfm-control"
                                  type="checkbox" // Use checkbox instead of radio for a toggle switch
                                  name="allow_submit"
                                  id="allow_submit"
                                  data-title="Allow Submit"
                                  checked={this.state.allow_submit}
                                  onChange={this.handleSubmitChange}
                                />
                                <label
                                  className="nk-kycfm-label"
                                  htmlFor="allow_submit"
                                >
                                  <span className="nk-kycfm-label-icon">
                                    <div className="label-icon fs-22px">
                                      <em class="icon ni ni-done"></em>
                                    </div>
                                  </span>
                                  <span className="nk-kycfm-label-text">
                                    Allow Submit
                                  </span>
                                </label>
                              </li>
                              <li className="nk-kycfm-control-item">
                                <input
                                  className="nk-kycfm-control"
                                  type="checkbox" // Use checkbox instead of radio for a toggle switch
                                  name="link_form"
                                  id="link_form"
                                  data-title="Link Only"
                                  checked={this.state.link_form}
                                  onChange={this.handleLinkChange}
                                />
                                <label
                                  className="nk-kycfm-label"
                                  htmlFor="link_form"
                                >
                                  <span className="nk-kycfm-label-icon">
                                    <div className="label-icon fs-22px">
                                      <em class="icon ni ni-link-alt"></em>
                                    </div>
                                  </span>
                                  <span className="nk-kycfm-label-text">
                                    Link Only
                                  </span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {this.state.showOnDashboard && (
                          <>
                            <div className="col-lg-12">
                              <h6>Show on Dashboard Settings</h6>
                            </div>
                            <div className="col-lg-2 customStyle-sqnc-form">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Set the sequence of this form (e.g 1)."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Sequence #{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="form_sequence"
                                    name="form_sequence"
                                    type="number"
                                    label="Sequence Number"
                                    value={this.state.form_sequence}
                                    inputProps={{ min: 1, max: 9 }}
                                    onChange={this.handleSequenceChange}
                                    variant="outlined"
                                    fullWidth
                                    style={{ height: "0.68em" }}
                                  />
                                  {this.state.form_sequence_error !== "" && (
                                    <p
                                      style={{
                                        color: "red",
                                        marginTop: "30px",
                                      }}
                                    >
                                      {this.state.form_sequence_error}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-5 customStyle-sqnc-form">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="How can the channel partner/client access this form? e.g(Get Started)"
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Button Text{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="btn_text"
                                    name="btn_text"
                                    type="text"
                                    label="Button Text"
                                    value={this.state.btn_text}
                                    onChange={this.handleChange}
                                    inputProps={{ maxLength: 100 }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-5 customStyle-sqnc-form">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Enter another button label."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Submission View{" "}
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="submission_text"
                                    name="submission_text"
                                    type="text"
                                    label="Submission View"
                                    value={this.state.submission_text}
                                    onChange={this.handleChange}
                                    inputProps={{ maxLength: 100 }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {this.state.link_form && (
                          <>
                            <div className="col-lg-12">
                              <h6>Form Link Settings</h6>
                            </div>

                            <div className="col-lg-12 customStyle-sqnc-form">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Enter form link here"
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Link <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="external_link"
                                    name="external_link"
                                    type="text"
                                    label="Link"
                                    value={this.state.external_link}
                                    onChange={this.handleChange}
                                    inputProps={{ maxLength: 255 }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <table className="table table-striped">
                                  <tbody>
                                    <tr>
                                      <th scope="row">Dynamic Parameters</th>
                                      <td>
                                        <ul className="preview-list customStyle-badgeTransfrom">
                                          {this.state.va_intergation ===
                                            "Yes" ? (
                                            <>
                                              <li className="preview-item">
                                                <a
                                                  className="badge badge-outline-gray"
                                                  onClick={() =>
                                                    this.handleBadgeClick(
                                                      "{{va_parameters}}"
                                                    )
                                                  }
                                                >
                                                  &#123;&#123;va_parameters&#125;&#125;
                                                </a>
                                                {this.state.showTooltip &&
                                                  this.state.copiedBadge ===
                                                  "{{va_parameters}}" && (
                                                    <div className="copyToClipboardTooltip active">
                                                      <span>
                                                        Copied to clipboard!
                                                      </span>
                                                    </div>
                                                  )}
                                              </li>
                                              <li className="preview-item">
                                                <a
                                                  className="badge badge-outline-gray"
                                                  onClick={() =>
                                                    this.handleBadgeClick(
                                                      "{{va_parameters_brands}}"
                                                    )
                                                  }
                                                >
                                                  &#123;&#123;va_parameters_brands&#125;&#125;
                                                </a>
                                                {this.state.showTooltip &&
                                                  this.state.copiedBadge ===
                                                  "{{va_parameters_brands}}" && (
                                                    <div className="copyToClipboardTooltip active">
                                                      <span>
                                                        Copied to clipboard!
                                                      </span>
                                                    </div>
                                                  )}
                                              </li>
                                            </>
                                          ) : null}
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                        {/* <div className="col-lg-12">
                          <div className="form-group text-right">
                            <button
                              type="button"
                              onClick={() => this.formSave()}
                              className="btn btn-lg btn-primary"
                              disabled={this.state.disabled}
                            >
                              Save
                            </button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-12">
                <div className="nk-block nk-block-lg">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      <div className="row g-4">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <FormBuilderClass
                              from="addForm"
                              form_title={this.state.form_title}
                              form_desc={this.state.form_desc}
                              form_content={this.state.form_content}
                              groups={this.state.groups}
                              onFormContentChange={this.handleFormContentChange}
                              onGroupsChange={this.handleGroupsChange}
                              onFormSubmit={this.handleFormSubmit}
                              link_form={this.state.link_form}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddForm);
